import { useContext } from 'react';
import useNavigation from '../../useNavigation';

import { FlexContext, IUnwindData } from '../../../contexts/flexContext';
import { IStructuredSnowplowEvent } from '../../useSnowplowTracker';

export interface IFlexNavigateUnwind extends IUnwindData {
  type: 'unwind';
  destinationId: string;
  snowplowEvent?: IStructuredSnowplowEvent;
}

const useFlexNavigateUnwind = () => {
  const flexContext = useContext(FlexContext);
  const navigation = useNavigation();

  const handleFlexNavigateUnwind = (flexNavigate: IFlexNavigateUnwind) => {
    const sequenceLength = flexContext.nodeSequence.length;
    const destinationNodeIndex = flexContext.nodeSequence.lastIndexOf(
      flexNavigate.destinationId
    );

    // If the node is unwinding to itself, we can update it directly.
    if (flexContext.node && destinationNodeIndex === sequenceLength - 1) {
      flexContext.unwind(flexNavigate, flexContext.node);

      return;
    }

    // If unwinding requires actual backwards navigation, the flex state, including the destination node,
    // is stored in browser history, and we cannot access it until after the navigation has taken place.
    // In this case, pass the unwind data to the Flex Context, where it will be applied to the cached state after the navigation.
    if (destinationNodeIndex >= 0) {
      flexContext.prepareUnwind(flexNavigate);

      navigation.pop(
        flexContext.nodeSequence.length - destinationNodeIndex - 1
      );
    }
  };

  return handleFlexNavigateUnwind;
};

export default useFlexNavigateUnwind;
