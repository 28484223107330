/* eslint-disable no-console */
import { useContext } from 'react';
import * as logger from '../../utils/remoteLogger';

import { AppConfigContext } from '../../contexts/appConfigContext';
import { IBaseAction } from '../useAction';
import WebPushService from '../../services/WebPushService';
import useOverlay from '../useOverlay';
import useLanguage from '../useLanguage';

export interface IWebPushNotificationAction extends IBaseAction {
  type: 'notifications_request_permission';
  data: {};
}

const useWebPushNotificationAction = () => {
  const { appConfig } = useContext(AppConfigContext);
  const overlay = useOverlay();
  const language = useLanguage();
  const pushState = WebPushService.getState();

  const handleWebPushNotificationAction = async (
    action: IWebPushNotificationAction
  ) => {
    logger.logDebug(
      'webPush',
      `WebPushNotificationAction handler ${action}, current state is ${pushState}`
    );
    switch (pushState) {
      case 'unknown':
        // means not supported, do nothing for the moment
        break;
      case 'not_determined':
        overlay.presentIconAlert({
          image: { url: 'https://appresources.kry.se/icons/Bell-Icon.png' },
          orientation: 'vertical',
          title: language.get('web_browser_push_permission_prompt_title'),
          message: language.get('web_browser_push_permission_prompt_body'),
          fullscreenOnMobile: true,
          items: [
            {
              title: language.get('web_browser_push_permission_prompt_cta'), // 'Turn on Notifications',
              style: 'normal',
              onClick: async () => {
                await WebPushService.initialise(
                  appConfig.webPushVapidKeyPublic
                );
              },
            },
            {
              title: language.get('web_browser_push_permission_prompt_skip'),
              style: 'cancel',
              onClick: () => {},
            },
          ],
        });
        break;
      case 'disabled':
        overlay.presentBasicAlert({
          title: language.get('web_browser_push_permission_disabled_title'),
          message: language.get('web_browser_push_permission_disabled_body'),
          fullscreenOnMobile: true,
        });
        break;
      case 'enabled': {
        await WebPushService.initialise(appConfig.webPushVapidKeyPublic);
        break;
      }
    }
  };

  return handleWebPushNotificationAction;
};

export default useWebPushNotificationAction;
