import { logInfo, logError, logWarning } from '../utils/remoteLogger';
import request from '../utils/request';

const states = ['enabled', 'disabled', 'not_determined', 'unknown'] as const;
type PushState = typeof states[number];

class WebPushService {
  private static TAG = 'webPush';

  private static capabilities = [
    () => 'Notification' in window,
    () => 'PushManager' in window,
    () => 'serviceWorker' in navigator,
    () => 'showNotification' in ServiceWorkerRegistration.prototype,
  ];

  static hasCapabilities = () =>
    WebPushService.capabilities.every((test) => test());

  static getState(): PushState {
    const isCapable = WebPushService.hasCapabilities();
    if (!isCapable) return 'unknown';
    switch (Notification.permission) {
      case 'granted':
        return 'enabled';
      case 'denied':
        return 'disabled';
      case 'default':
        return 'not_determined';
    }
  }

  static async requestPermission() {
    return Notification.requestPermission();
  }

  static isNotGranted() {
    return Notification.permission !== 'granted';
  }

  static async initialise(vapidPublicKey: string) {
    const hasSupport = WebPushService.hasCapabilities();
    if (!hasSupport) {
      logInfo(
        WebPushService.TAG,
        'Browser does not support web push notifications'
      );
      return;
    }
    // beware that Chrome has a weird behavior and won't request permission after 3 trials
    // and will returns 'denied' even if it should return 'default'
    if (Notification.permission === 'default') {
      await WebPushService.requestPermission();
    }

    // Check if user has disabled notifications
    // If a user has manually disabled notifications in his/her browser for
    // your page previously, they will need to MANUALLY go in and turn the
    // permission back on. In this statement you could show some UI element
    // telling the user how to do so.
    if (WebPushService.isNotGranted()) {
      logInfo(
        WebPushService.TAG,
        `The user has not granted notifications: current permission is ${Notification.permission}`
      );
      return;
    }

    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    // Get the push notification subscription object
    let subscription =
      await serviceWorkerRegistration.pushManager.getSubscription();
    // If this is the user's first visit we need to set up
    // a subscription to push notifications
    if (subscription) {
      logInfo(
        WebPushService.TAG,
        `Existing subscription from push manager: ${WebPushService.toJson(
          subscription
        )}`
      );
    } else {
      const newSub = await WebPushService.subscribe(vapidPublicKey);
      if (newSub) {
        logInfo(
          WebPushService.TAG,
          `New subscription: ${WebPushService.toJson(newSub)}`
        );
        // Update the server state with the new subscription
      }
      subscription = newSub;
    }
    // always republish the subscription (even when recycled)
    return WebPushService.publish(subscription);
  }

  /**
   * Step three: Create a subscription. Contact the third party push server (for
   * example mozilla's push server) and generate a unique subscription for the
   * current browser.
   */

  static async subscribe(vapidPublicKey: string) {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    // Contact the third party push server. Which one is contacted by
    // pushManager is  configured internally in the browser, so we don't
    // need to worry about browser differences here.
    //
    // When .subscribe() is invoked, a notification will be shown in the
    // user's browser, asking the user to accept push notifications from
    // <yoursite.com>. This is why it is async and requires a catch.
    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: vapidPublicKey,
    });

    return subscription;
  }

  static async publish(subscription: PushSubscription) {
    try {
      await WebPushService.sendSubscriptionToServer(subscription);
      return subscription;
    } catch (e) {
      if (WebPushService.isNotGranted()) {
        logInfo(
          WebPushService.TAG,
          `The user has not granted notifications: current permission is ${Notification.permission}`
        );
      } else {
        logError(
          WebPushService.TAG,
          'Unable to send subscription to server',
          e as Error
        );
      }
      return undefined;
    }
  }

  static toJson(subscription: PushSubscription) {
    // Get public key and user auth from the subscription object
    const key = subscription.getKey ? subscription.getKey('p256dh') : '';
    const auth = subscription.getKey ? subscription.getKey('auth') : '';
    return JSON.stringify(
      {
        endpoint: subscription.endpoint,
        // Take byte[] and turn it into a base64 encoded string suitable for
        // POSTing to a server over HTTP
        key: key
          ? btoa(
              String.fromCharCode.apply(
                null,
                Array.from<number>(new Uint8Array(key))
              )
            )
          : '',
        auth: auth
          ? btoa(
              String.fromCharCode.apply(
                null,
                Array.from<number>(new Uint8Array(auth))
              )
            )
          : '',
      },
      null,
      2
    );
  }

  /**
   * Step four: Send the generated subscription object to our server.
   */
  static async sendSubscriptionToServer(subscription: PushSubscription) {
    const subJson = WebPushService.toJson(subscription);
    await request('/api/push-notification/user/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { json_token: subJson },
    });
    logInfo(WebPushService.TAG, `Subscription sent to PNS`);
    return subscription;
  }

  static async getSubscription() {
    const registration = await navigator.serviceWorker.ready;
    return registration.pushManager.getSubscription();
  }
}

export default WebPushService;
