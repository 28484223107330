import request from './request';

let loggingEnabled = false;

enum LogLevel {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

type ILogAttributes = Record<string, string>;

const levelToConsole = (level: LogLevel) =>
  !!console && level in console ? console[level] : undefined;

const postLog = (
  level: LogLevel,
  tag: string,
  message: string,
  errorOrAttributes?: Error | ILogAttributes
) => {
  // If remote logging is disabled, return.
  if (!loggingEnabled) {
    const args = [`[${tag}] ${message}`, errorOrAttributes].filter((e) => !!e);
    levelToConsole(level)?.(...args);
    return;
  }

  // If an Error is provided, serialize it to a string and pass it
  // as an attribute error property.
  const attributes =
    errorOrAttributes instanceof Error
      ? {
          error: JSON.stringify(
            errorOrAttributes,
            Object.getOwnPropertyNames(errorOrAttributes)
          ),
        }
      : errorOrAttributes;

  request('/api/log', {
    method: 'POST',
    body: {
      ...attributes,
      ...{
        level,
        message,
        logger_name: tag,
        timestamp: new Date().toISOString(),
      },
    },
    silent: true,
    retryable: true,
    persistent: true,
  }).catch(() => {
    // Fail silently.
  });
};

export const enableRemoteLogging = (shouldEnable: boolean) => {
  loggingEnabled = shouldEnable;
};

export const logError = (
  tag: string,
  message: string,
  errorOrAttributes?: Error | ILogAttributes
) => {
  postLog(LogLevel.ERROR, tag, message, errorOrAttributes);
};

export const logWarning = (
  tag: string,
  message: string,
  errorOrAttributes?: Error | ILogAttributes
) => {
  postLog(LogLevel.WARN, tag, message, errorOrAttributes);
};

export const logInfo = (
  tag: string,
  message: string,
  errorOrAttributes?: Error | ILogAttributes
) => {
  postLog(LogLevel.INFO, tag, message, errorOrAttributes);
};

export const logDebug = (
  tag: string,
  message: string,
  errorOrAttributes?: Error | ILogAttributes
) => {
  postLog(LogLevel.DEBUG, tag, message, errorOrAttributes);
};

export const logTrace = (
  tag: string,
  message: string,
  errorOrAttributes?: Error | ILogAttributes
) => {
  postLog(LogLevel.TRACE, tag, message, errorOrAttributes);
};
